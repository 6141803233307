@import "./global.scss";

.popupContainer {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    left: 0;

    .popup {
        width: 70%;
        height: 80vh;
        // background-color: white;
        background: linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url('../../../public/momo-image/IMG_2233.jpg');
        background-size: cover;
        border-radius: 20px;
        // overflow: hidden;

        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        .closeBtn {
            position: absolute;
            width: 30px;
            height: 30px;
            background-color: white;
            border: 2px solid black;
            border-radius: 20px;
            font-size: 15px;

            vertical-align: middle;
            text-align: center;
            cursor: pointer;

            top: 0;
            right: 0;
            transform: translate(25%, -25%);
        }
    }
}

.popUpTrigger {
    background-color: transparent;
    border: none;
    cursor: pointer;
    display: block;
    padding: 0;
}