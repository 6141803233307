@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

@keyframes BT {
    from {
        margin-top: 400px;
        opacity: 0;
    }
    to {
        margin-top: 0;
        opacity: 1;
    }
}