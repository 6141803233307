.Nav {
    width: calc(100% - 40px);
    background-color: pink;
    display: flex;
    align-items: center;
    padding: 5px 20px;

    .momoIcon {
        width: 40px;
    }

    ul {
        list-style-type: none;
        padding-left: 30px;

        li {
            text-decoration: none;
            color: white;

            > a {
                text-decoration: none;
                color: white;
            }
        }
    }
}